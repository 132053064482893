<template>
    <section class="pt-4">
        <div class="row mx-0 align-items-center bg-white br-8 py-3 px-2">
            <div class="col-2 text-general f-18 border-right f-600">
                {{$config.cliente + 's'}} con <br /> datos faltantes :
            </div>
            <div class="col text-center text-general border-right">
                <p class="f-30 f-600">{{ contarnacimiento }}</p>
                <p class="f-14">Fecha de nacimiento</p>
            </div>
            <div class="col text-center text-general border-right">
                <p class="f-30 f-600">{{ contargenero }}</p>
                <p class="f-14">Genero otro</p>
            </div>
            
            <div class="col text-center text-general border-right">
                <p class="f-30 f-600">{{ contarpassword }}</p>
                <p class="f-14">Contraseña</p>
            </div>
            <div class="col text-center text-general">
                <p class="f-30 f-600">{{ porcentaje }}%</p>
                <p class="f-14">{{$config.cliente + 's'}} con datos faltantes</p>
            </div>
        </div>
        <div class="row mx-0 py-2" />
        <div class="row mx-0">
            <div class="col-12 px-0 position-relative">
                <cargando v-if="cargando"/>
                <tabla-general :data="datos" class-header="text-general f-16" :usar-paginacion="true" alto="calc(100vh - 415px)">
                    <template slot="cabecera-izquierda">
                        <div class="col-auto text-general f-18 f-500">
                            {{$config.cliente + 's'}} con datos faltantes
                        </div>
                    </template>
                    <template slot="adicionales-izquierda">
                        <el-table-column
                        prop="nombre_cliente"
                        sortable
                        label="Nombre"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 align-left">
                                    {{ scope.row.nombre_cliente }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="nombre_tienda"
                        sortable
                        :label="$config.vendedor"
                        width="230"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 align-left">
                                    {{ scope.row.nombre_tienda }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="nombre_cedis"
                        sortable
                        label="Cedis"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 align-left">
                                    {{ scope.row.nombre_cedis }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="nacimiento"
                        sortable
                        label="Fecha de nacimiento"
                        width="210"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    {{ formatoFecha(scope.row.nacimiento) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="genero"
                        sortable
                        label="Genero"
                        class-name="text-center"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    {{ genero(scope.row.genero) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="created_at"
                        sortable
                        label="Tiempo registrado"
                        class-name="text-center"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    {{ fechaConteo(scope.row.created_at) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        class-name="text-center"
                        >
                            <template slot="header" slot-scope="scope">
                                <el-tooltip placement="bottom" content="Uso de contraseña" effect="light">
                                    <i class="icon-lock f-20" />
                                </el-tooltip>
                            </template>
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    <i v-if="scope.row.password" class="icon-ok-circled-outline text-green f-17" />
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
import datosCuentas from '~/services/DatosCuentas/datosCuentas'
export default {
    data(){
        return {
            cargando: true,
            datos: [],
            contarnacimiento: 0,
            contarpassword: 0,
            contargenero: 0}
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        }),
        porcentaje(){
            return this.datos.length? Math.round((this.datos.length*100)/this.contarclientes): 0

        }
    },
    watch: {
        async id_cedis(val){
            await this.listarClientes()
        },
    },
    mounted(){
        this.listarClientes()
    },
    methods: {    
        async listarClientes(){
            try{
                const {data} = await datosCuentas.listarCuentasClientes()
                this.datos = data.data        
                this.contarnacimiento = data.nacimiento
                this.contargenero = data.genero
                this.contarpassword = data.passwords
                this.contarclientes = data.total
            }catch(error){
                return this.error_catch(error)
            }finally{
                this.cargando = false
            }

        },
        fechaConteo(fecha){
            if(fecha != ""){
                let month = moment().diff(moment(fecha),"month")
                return `${month} ${month == 1 ? 'Mes':'Meses'}`
            }else{
                return "-"
            }
        },
        genero(genero){
            
            switch(genero){
            case 1:
                return "Otro";
                break;
            case 2:
                return "Masculino";
                break;
            case 3:
                return "Femenino";
                break;
            default: 
                return "No registra";
                break;
            }
        },
        formatoFecha(date){
            if(date){
                return this.formatearFecha(date,'D MMM Y');
            }
            else{
                return "-"
            }
        }
    }
}
</script>